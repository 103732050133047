<template>
	<div class="success" @click="finish">
		<slot />
		{{ text }}
		<h4 v-if="subtitle!=''">
			{{ subtitle }}
		</h4>
	</div>
</template>

<script>
import { gsap, Back, Quad } from 'gsap/gsap-core'

export default {
	name: 'Success',
	emits: ['finish'],
	props: {
		text: {
			type: String,
			default: 'Bravo!!'
		},
		subtitle: {
			type: String,
			default: ''
		}
	},
	mounted () {
		this.show()
		gsap.delayedCall(1.6, this.finish)
	},
	methods: {
		show () {
			gsap.fromTo(this.$el, { opacity: 0, scale: 0.9 }, { opacity: 1, scale: 1, duration: 1, ease: Back.easeOut })
		},
		hide (cb) {
			gsap.to(this.$el, { opacity: 0, scale: 1.1, duration: 0.5, ease: Quad.easeOut, onComplete: () => { if (cb)cb() } })
		},
		finish () {
			gsap.killTweensOf(this.finish)
			this.hide(() => {
				this.$emit('finish')
			})
		}
	}
}
</script>

<style lang="stylus" scoped>

.success
	cursor pointer
	font-size 36px
	color #fff
	text-align center
	position absolute
	top 40%
	left 50%
	user-select none
	transform translate3d(-50%,-50%,0)
	will-change transform opacity

</style>
