<template>
	<div class="next">
		Continue >
	</div>
</template>

<script>
import { gsap } from 'gsap/all'

export default {
	name: 'ContinueButton',
	methods: {
		show (delay = 0.8, cb = null) {
			gsap.from(this.$el, { delay: delay, opacity: 0, duration: 0.5, y: 15, onComplete: cb })
		},
		hide () {
			gsap.to(this.$el, { opacity: 0, x: 25 })
		}
	}
}
</script>

<style lang="stylus" scoped>

	.next
		border-radius: 25px
		font-size 12px
		border 2px solid #fff
		display inline-block
		padding 10px 20px
		cursor pointer
		user-select none

</style>
